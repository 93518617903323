var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.reportData,"loading":_vm.loadReport,"loading-text":"Loading data ... Please wait"},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateNotTime")(item._id))+" ")]}},{key:"item.success",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.success))+" ")]}},{key:"item.failed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.failed))+" ")]}},{key:"item.notSend",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.notSend))+" ")]}},{key:"item.openedPush",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.openedPush))+" ")]}},{key:"item.openedInbox",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.openedInbox))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.total))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }