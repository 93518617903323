<template>
  <div class="mt-5">
    <v-data-table
      dense
      :headers="headers"
      :items="reportData"
      :loading="loadReport"
      loading-text="Loading data ... Please wait">
      <template class="tableSimpleHeader" 
        v-slot:[`item._id`]="{ item }">
        {{ item._id | formatDateNotTime }}
      </template>
      <template class="tableSimpleHeader" 
        v-slot:[`item.success`]="{ item }">
        {{ item.success | toNumber }}
      </template>
      <template class="tableSimpleHeader" 
        v-slot:[`item.failed`]="{ item }">
        {{ item.failed | toNumber }}
      </template>
      <template class="tableSimpleHeader" 
        v-slot:[`item.notSend`]="{ item }">
        {{ item.notSend | toNumber }}
      </template>
      <template class="tableSimpleHeader" 
        v-slot:[`item.openedPush`]="{ item }">
        {{ item.openedPush | toNumber }}
      </template>
      <template class="tableSimpleHeader" 
        v-slot:[`item.openedInbox`]="{ item }">
        {{ item.openedInbox | toNumber }}
      </template><template class="tableSimpleHeader" 
        v-slot:[`item.total`]="{ item }">
        {{ item.total | toNumber }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ReportPushPerformanceSummary',
  props: {
    reportData: {
      type: Array
    },
    
    loadReport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Date',
          align: 'center',
          sortable: true,
          value: '_id'
        },
        {
          text: 'Success',
          align: 'center',
          sortable: true,
          value: 'success'
        },

        {
          text: 'Failed',
          align: 'center',
          sortable: true,
          value: 'failed'
        },


        {
          text: 'Not Send',
          align: 'center',
          sortable: true,
          value: 'notSend'
        },

        {
          text: 'Opened Push',
          align: 'center',
          sortable: true,
          value: 'openedPush'
        },

        {
          text: 'Opened Inbox',
          align: 'center',
          sortable: true,
          value: 'openedInbox'
        },

        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total'
        },
      ]
    }
  }
}
</script>